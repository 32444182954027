<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    class="mt-2"
    :loading="!modelHasId"
  >
    <VRow class="deliverable-report justify-center">
      <VCol cols="10">
        <VRow class="mx-0">
          <VCol 
            cols="12"
            class="py-3"
          >
            <h2 style="font-size: 23px;">
              {{ model.title }}
            </h2>
            <p
              v-if="model.content"
              v-linkified
              v-html="model.content"
              class="formatted pt-2 pr-5"
              style="font-size: 15px;"
            />
            <FilesList
              :readonly="true"
              :files="model.files"
            />
          </VCol>
          <VCol 
            cols="12"
            class="py-3"
          >
            <VBtn height="58px">
              <img src="@/images/program-dashboard-icon.png">
              <span 
                class="font-weight-medium pl-2"
                style="font-size: 15px;"
              >
                Displaying Data for 43 Hubs
              </span>
            </VBtn>
            <VMenu
              bottom
              right
              offset-y
              close-on-content-click
              nudge-bottom="4"
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  v-bind="attrs"
                  v-on="on"
                  height="58px"
                  class="ml-4"
                >
                  <VIcon>mdi-calendar</VIcon>
                  <span 
                    class="font-weight-medium pl-1"
                    style="font-size: 15px;"
                  >
                    Date Range: {{ filterByDateRange == 'all' ? 'All Time' : `Last ${filterByDateRange} Days` }}
                  </span>
                </VBtn>
              </template>
              <VList style="border-radius: 10px;">
                <VRadioGroup
                  hide-details
                  class="ma-0"
                  :value="filterByDateRange"
                >
                  <VListItem
                    link
                    class="px-2"
                    @click="filterByDateRange = 'all'"
                  >
                    <VListItemTitle class="d-flex align-center">
                      <VRadio
                        color="success"
                        class="custom-radio mx-1 mb-1"
                        value="all"
                      />
                      <span
                        class="font-weight-medium"
                        style="font-size: 14px;"
                      >
                        All Time
                      </span>
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    v-for="(range, index) in dateRange"
                    link
                    class="px-2"
                    :key="index"
                    @click="filterByDateRange = range.value"
                  >
                    <VListItemTitle class="d-flex align-center">
                      <VRadio
                        color="success"
                        class="custom-radio mx-1 mb-1"
                        :value="range.value"
                      />
                      <span
                        class="font-weight-medium"
                        style="font-size: 14px;"
                      >
                        {{ range.title }}
                      </span>
                    </VListItemTitle>
                  </VListItem>
                </VRadioGroup>
              </VList>
            </VMenu>
          </VCol>
          <VCol 
            cols="12"
            class="py-3"
          >
            <VCard class="title-border-none">
              <VCardTitle class="no-title-border">
                <h2 style="font-size: 18px;">
                  Total {{ featureName(modelType) }} Responses
                </h2>
              </VCardTitle>
              <VCardText class="pt-0">
                <VCard
                  color="#FFC100"
                  height="40px"
                  class="d-flex align-center justify-center rounded-pill font-weight-bold mx-2"
                  :style="allResponsesStyle"
                >
                  Of the 484 users who started this form, 343 ({{ allResponsesPercentage }}%) completed it.
                </VCard>
                <VRow class="mx-0 mt-2">
                  <VCol 
                    cols="12"
                    class="pb-0"
                  >
                    <h2 style="font-size: 18px;">
                      Form Responses Over Time
                    </h2>
                    <h4 
                      class="font-weight-regular pt-1 pb-6"
                      style="font-size: 15px;"
                    >
                      Users who started and completed the form in the selected time period.
                    </h4>
                    <highcharts :options="responseChartOptions" />
                  </VCol>
                </VRow>
              </VCardText>
            </VCard>
            <VDivider 
              class="mt-10 mb-2" 
              style="background-color: black;" 
            />
          </VCol>
          <VCol 
            v-for="(section, index) in sectionsWithMetadata"
            cols="12"
            class="py-3"
            :key="index"
          >
            <h2 class="text-h5 font-weight-bold">
              {{ section.title }}
            </h2>
            <p
              v-if="section.description"
              v-linkified
              v-html="section.description"
              class="formatted pt-2"
              style="font-size: 15px;"
            />
            <VRow>
              <VCol 
                v-for="(metadata, key) in section.metadata"
                cols="12"
                class="py-3"
                :key="key"
              >
                <h2 class="text-h6 font-weight-bold pb-2">
                  {{ metadata.name || '(no name)' }}
                </h2>
                <VCard class="title-border-none">
                  <VCardTitle class="no-title-border">
                    <h2 style="font-size: 18px;">
                      Responses
                    </h2>
                  </VCardTitle>
                  <VCardText class="py-0 px-4">
                    <h4 
                      v-if="metadata.is_required"
                      class="mb-4"
                      style="font-size: 15px;"
                    >
                      This question required a response to submit the form.
                    </h4>
                    <VCard
                      v-else
                      color="#FFC100"
                      height="40px"
                      class="d-flex align-center justify-center rounded-pill font-weight-bold mx-2 mb-4"
                      :style="metadataResponseStyle"
                    >
                      297 of 343 ({{ metadataResponsePercentage }}%) form respondents answered this question.
                    </VCard>
                    <highcharts 
                      v-if="optionalQuestionsTypes.includes(metadata.type)"
                      :options="getMetadataChartOptions(metadata)" 
                    />
                    <DataViewUI
                      hide-actions
                      hide-main-title
                      hide-item-actions
                      hide-records-info
                      sort-by="submitted_on"
                      :data="metadataResponses"
                      :headers="metadataResponsesHeaders(metadata)"
                    >
                      <template #items-cells="props">
                        <td 
                          v-if="!optionalQuestionsTypes.includes(metadata.type)"
                          class="simple"
                        >
                          <UserModal :user="props.item.user" />
                        </td>
                        <td v-if="!optionalQuestionsTypes.includes(metadata.type)">
                          {{ $timezone.formatDateTime(props.item.submitted_on) }}
                        </td>
                        <td 
                          :class="{
                            'simple': optionalQuestionsTypes.includes(metadata.type),
                          }"
                        >
                          <div class="d-flex align-center my-4">
                            <b 
                              v-if="optionalQuestionsTypes.includes(metadata.type)"
                              class="text-h6 font-weight-bold mr-6"
                            >
                              {{ String.fromCharCode(props.index + 65) }}
                            </b>
                            <div 
                              :ref="`response-${metadata.id}-${props.item.user.id}`"
                              :class="{
                                'text-wrap': true,
                                'response': !optionalQuestionsTypes.includes(metadata.type),
                              }"
                              :style="{
                                'cursor': isResponseClamped(metadata, props.item, props.index) ? 'pointer' : 'auto',
                              }"
                              @click="showFullResponse(metadata, props.item, props.index)"
                            >
                              {{ props.item.response }}
                            </div>
                          </div>
                        </td>
                        <td v-if="optionalQuestionsTypes.includes(metadata.type)">
                          75
                        </td>
                        <td v-if="optionalQuestionsTypes.includes(metadata.type)">
                          25%
                        </td>
                      </template>
                    </DataViewUI>
                  </VCardText>
                </VCard>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
        <VDialog
          max-width="680px"
          :dismissable="false"
          :persistent="false"
          :value="dialogs.response.status"
          @click:outside="dialogs.response.status = false"
        >
          <VRow
            v-if="dialogs.response.data.hasOwnProperty('metadata')"  
            class="white mx-0 px-7 pt-4"
          >
            <VCol cols="12">
              <h2 class="text-h6 font-weight-bold pb-2">
                {{ dialogs.response.data.metadata.name || '(no name)' }}
              </h2>
              </VCol>
            <VCol 
              cols="12"
              class="py-0"
            >  
              <VCarousel 
                v-model="dialogs.response.data.index"
                hide-delimiters
                height="100%"
                next-icon="mdi-arrow-right"
                prev-icon="mdi-arrow-left"
                class="d-flex justify-center response-carousel"
                :continuous="false"
              >
                <VCarouselItem
                  v-for="(metadata, index) in metadataResponses.data"
                  :key="index"
                >
                  <VCard 
                    color="rgba(255, 193, 0, 0.2)"
                    width="540px"
                    class="py-3 px-8"
                  >
                    <VCardText>
                      <div class="d-inline-flex align-center">
                        <VAvatar
                          color="primary"
                          size="68px"
                          class="mb-3 mr-4"
                        >
                          <VImg
                            v-if="!metadata.user"
                            src="https://www.gravatar.com/avatar/475760632cec1a865abf22b79269f099.jpg?s=200&d=mm"
                          />
                          <img
                            v-else
                            :src="metadata.user.photo_url"
                          >
                        </VAvatar>
                        <h3 style="font-size: 19px;">
                          {{ metadata.user.name }}
                        </h3>
                      </div>
                      <p class="text-subtitle-2">
                        {{ metadata.response }}
                      </p>
                      <p class="text-subtitle-2 font-weight-bold">
                        Responded on {{ $timezone.formatDateTime(metadata.submitted_on) }}
                      </p>
                    </VCardText>
                  </VCard>
                  <h4 
                    class="text-center py-8"
                    style="font-size: 15px;"
                  >
                    Response {{ dialogs.response.data.index + 1 }} of {{ metadataResponses.data.length }}
                  </h4>
                </VCarouselItem>
              </VCarousel>
            </VCol>
          </VRow>
        </VDialog>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import ViewMixin from "@/mixins/View";
export default {
  mixins: [ViewMixin],
  data() {
    return {
      modelType: "Deliverable",
      // leftMenuItemsForReport: [],
      filterByDateRange: "all",
      allResponsesPercentage: 71,
      metadataResponsePercentage: 86,
      optionalQuestionsTypes: ['Checkbox', 'Dropdown', 'Radio', 'Rating'],
      dialogs: {
        response: {
          data: {},
          status: false,
        },
      },
      responseChartOptions: {
        xAxis: {
          startOnTick: true,
          type: 'datetime',
          tickInterval: 24 * 3600 * 1000,
          min: new Date('2025-03-05T00:00:00.801Z').getTime(),
          max: new Date().getTime(),
          dateTimeLabelFormats: {
            day: '%d %b %Y',
          }
        },
        yAxis: {
          title: {
            text: 'Number of Responses',
          }
        },
        series: [
          {
            name: 'Form Started',
            data: [
              [new Date('2025-03-05T00:00:00.801Z').getTime(), 0],
              [new Date('2025-03-07T00:00:00.801Z').getTime(), 5],
              [new Date('2025-03-08T00:00:00.801Z').getTime(), 7],
              [new Date('2025-03-10T00:00:00.801Z').getTime(), 12],
              [new Date('2025-03-13T00:00:00.801Z').getTime(), 9],
              [new Date('2025-03-20T00:00:00.801Z').getTime(), 11],
              [new Date('2025-03-21T00:00:00.801Z').getTime(), 12],
            ]
          }, 
          {
            name: 'Form Completed',
            data: [
              [new Date('2025-03-05T00:00:00.801Z').getTime(), 0],
              [new Date('2025-03-07T00:00:00.801Z').getTime(), 2],
              [new Date('2025-03-08T00:00:00.801Z').getTime(), 5],
              [new Date('2025-03-10T00:00:00.801Z').getTime(), 8],
              [new Date('2025-03-13T00:00:00.801Z').getTime(), 7],
              [new Date('2025-03-20T00:00:00.801Z').getTime(), 10],
              [new Date('2025-03-21T00:00:00.801Z').getTime(), 11],
            ]
          },
        ],
      }
    };
  },
  computed: {
    allResponsesStyle() {
      return {
        background: `linear-gradient(90deg, #FFC100 0%, #FFC100 ${this.allResponsesPercentage}%, #D6D6D6 ${this.allResponsesPercentage}%)`,
        fontSize: '15px',
      };
    },
    metadataResponseStyle() {
      return {
        background: `linear-gradient(90deg, #FFC100 0%, #FFC100 ${this.metadataResponsePercentage}%, rgba(255, 193, 0, 0.2) ${this.metadataResponsePercentage}%)`,
        fontSize: '15px',
      };
    },
    sectionsWithMetadata() {
      if(this.modelHasId) {
        return this.model.metadata_sections.map((section) => {
          return {
            ...section,
            metadata: this.model.metadata.filter((metadata) => metadata.section === section.title),
          };
        });
      }
      return {};
    },
    dateRange() {
      return [
        {
          title: 'Last 90 Days',
          value: '90',
        },
        {
          title: 'Last 30 Days',
          value: '30',
        },
        {
          title: 'Last 7 Days',
          value: '7',
        },
      ];
    },
    metadataResponses() {
      return {
        data: [
          {
            user: this.model.user,
            submitted_on: '2025-03-01T13:09:01.000000Z',
            response: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Morbi commodo, ipsum sed pharetra gravida, orci magna rhoncus neque, id pulvinar odio lorem non turpis. Nullam sit amet enim. Suspendisse id velit vitae ligula volutpat condimentum. Aliquam erat volutpat. Sed quis velit. Nulla facilisi. Nulla libero. Vivamus pharetra posuere sapien. Nam consectetuer. Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Donec porttitor ligula eu dolor. Maecenas vitae nulla consequat libero cursus venenatis. Nam magna enim, accumsan eu, blandit sed, blandit a, eros.',
          },
          {
            user: this.model.user,
            submitted_on: '2025-03-02T13:09:01.000000Z',
            response: 8,
          },
          {
            user: this.model.user,
            submitted_on: '2025-03-03T13:09:01.000000Z',
            response: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions({
      doGetDeliverable: "deliverable/getOne",
    }),
    onGet(id) {
      return this.doGetDeliverable(id).then((result) => {
        // For the form report left menu items
        /* result.metadata_sections.map((section) => {
          const sectionMetadata = result.metadata.filter((metadata) => metadata.section === section.title);
          const subGroupItems = sectionMetadata.map((metadata) => {
            return {
              enabled: true,
              title: metadata.name || '(no name)',
              route: {
                name: 'deliverable.report',
                params: {
                  id: this.$route.params.id,
                  team: this.$team.slug,
                },
                hash: "#" + (metadata.name || '(no name)') + "-" + metadata.id,
              },
            };
          });
          this.leftMenuItemsForReport.push({
            title: section.title,
            enabled: true,
            icon: false,
            isImage: false,
            subGroup: true,
            category: "deliverableReport",
            routeName: "deliverable.report",
            subGroupItems: subGroupItems,
          });
        });
        this.$bus.$emit("deliverableReportMenuItems", this.leftMenuItemsForReport); */
        return result;
      });
    },
    getMetadataChartOptions(metadata) {
      return {
        chart: {
          type: 'column',
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          column: {
            pointWidth: 70,
          },
        },
        xAxis: {
          categories: metadata.type == 'Rating' ? [...Array(5).keys()].map(x => ++x) : [...Array(metadata.options.options.length).keys()].map(i => String.fromCharCode(i + 65)),
        },
        yAxis: {
          title: {
            text: 'Number of Responses',
          }
        },
        series: [
          {
            data: Array.from({length: metadata.type == 'Rating' ? 5 : metadata.options.options.length}, () => Math.floor(Math.random() * 40)),
          },
        ],
      };
    },
    metadataResponsesHeaders(metadata) {
      const isOptionalQuestion = this.optionalQuestionsTypes.includes(metadata.type);
      return [
        {
          text: "User",
          align: "left",
          value: "",
          sortable: true,
          enabled: !isOptionalQuestion,
        },
        {
          text: "Submitted On",
          align: "left",
          value: "submitted_on",
          sortable: true,
          enabled: !isOptionalQuestion,
        },
        {
          text: "Response",
          align: "left",
          value: "",
          sortable: false,
          enabled: true,
        },
        {
          text: "Count",
          align: "center",
          value: "",
          sortable: false,
          enabled: isOptionalQuestion,
        },
        {
          text: "Percent",
          align: "center",
          value: "",
          sortable: false,
          enabled: isOptionalQuestion,
        },
      ].filter((item) => item.enabled === true);
    },
    isResponseClamped(metadata, submittedData, index) {
      if(this.$refs[`response-${metadata.id}-${submittedData.user.id}`]) {
        const element = this.$refs[`response-${metadata.id}-${submittedData.user.id}`][index];
        return element.scrollHeight > element.clientHeight;
      }
    },
    showFullResponse(metadata, submittedData, index) {
      if(this.isResponseClamped(metadata, submittedData, index)) {
        this.dialogs.response.data = {
          index,
          metadata,
          submittedData,
        };
        this.dialogs.response.status = true;
      }
    },
  },
}
</script>
<style lang="scss">
.deliverable-report {
  .v-data-table__wrapper>table>thead>tr>th {
    color: inherit !important;
    border-bottom: 1px solid black !important;
    top: auto !important;
  }
  .response {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.response-carousel {
  .v-window__prev, .v-window__next {
    bottom: 24px;
    margin: 0px 66px;
    top: unset;

    .v-btn {
      background-color: #FFAB00;
      border-radius: 4px;
      height: 44px;
      width: 44px;
      .v-icon {
        font-size: 28px !important;
      }
    }
  }
}
</style>